import { Dispatch, FC, SetStateAction } from 'react';

import {
  Button,
  Container,
  ExpandableSection,
  Flashbar,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Spinner,
  Toggle
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { IPortalSettings } from '../../../../interfaces/portal/settings';
import { FlashbarProps } from '@awsui/components-react';

interface ViewProps {
  formValues: IPortalSettings;
  handleSubmittedForm: (data: IPortalSettings) => Promise<void>;
  flashBarItems: FlashbarProps.MessageDefinition[];
  viewDuoSecrets: boolean;
  setViewDuoSecrets: Dispatch<SetStateAction<boolean>>;
  settingsSectionExpanded: boolean;
  setSettingsSectionExpanded: Dispatch<SetStateAction<boolean>>;
  secretsSectionExpanded: boolean;
  setSecretsSectionExpanded: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

const View: FC<ViewProps> = ({
  formValues,
  handleSubmittedForm,
  flashBarItems,
  viewDuoSecrets,
  setViewDuoSecrets,
  settingsSectionExpanded,
  setSettingsSectionExpanded,
  secretsSectionExpanded,
  setSecretsSectionExpanded,
  isLoading
}) => {
  const validationSchema = yup.object({
    duoIntegration: yup.object({
      integrationKey: yup.string(),
      secretKey: yup.string(),
      directoryKey: yup.string(),
      apiEndpoint: yup.string(),
    }),
    portalConfiguration: yup.object({
      disableUserDueToInactivityThresholdInDays: yup.number().integer().not([0]),
      warnUserDueToInactivityThresholdInDays: yup.number().integer().not([0]),
      userPasswordResetWaitTimeInHours: yup.number().integer().not([0]),
      enableDisableUserDueToInactivity: yup.boolean(),
      enableWarnUserDueToInactivity: yup.boolean(),
      enableUserPasswordResetWaitTime: yup.boolean(),
      workDocsEnabled: yup.boolean(),
      workMailEnabled: yup.boolean(),
      workMailDomain: yup.string(),
      workMailOrgId: yup.string(),
      duoEnabled: yup.boolean(),
      enableAutoProvision: yup.boolean(),
      smsVerifyExpireTimeInSeconds: yup.number().integer().not([0]),
      smsVerifyMaxResends: yup.number().integer(),
      smsVerifyMaxFailures: yup.number().integer()
    })
  });

  return isLoading ? (
    <Spinner size="large" />
  ) : (
    <Formik
      validateOnChange={true}
      enableReinitialize={true}
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        handleSubmittedForm(data).then((resp) => {
          resetForm();
          setSubmitting(false);
        });
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
        <SpaceBetween direction="vertical" size="xs">
          <Flashbar items={flashBarItems} />
          <Form
            actions={
              <Button
                variant="primary"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                Submit
              </Button>
            }
          >
            <SpaceBetween direction="vertical" size="m">
              <ExpandableSection
                header="Settings"
                expanded={settingsSectionExpanded}
                onChange={({ detail }) => {
                  setSettingsSectionExpanded(detail.expanded);
                }}
              >
                <SpaceBetween direction="vertical" size="m">
                  <Container
                    header={<Header variant="h2">User Settings</Header>}
                  >
                    <SpaceBetween direction="vertical" size="xl">
                      <div>
                        <FormField
                          label="User Password Reset Wait Time (In Hours)"
                          errorText={
                            errors.portalConfiguration
                              ?.userPasswordResetWaitTimeInHours
                          }
                        >
                          <Input
                            type="text"
                            value={values.portalConfiguration.userPasswordResetWaitTimeInHours.toString()}
                            onChange={({ detail }) =>
                              setFieldValue(
                                'portalConfiguration.userPasswordResetWaitTimeInHours',
                                Number(detail.value)
                              )
                            }
                            invalid={
                              !!errors.portalConfiguration
                                ?.userPasswordResetWaitTimeInHours
                            }
                          />
                        </FormField>

                        <Toggle
                          onChange={({ detail }) =>
                            setFieldValue(
                              'portalConfiguration.enableUserPasswordResetWaitTime',
                              detail.checked
                            )
                          }
                          checked={
                            values.portalConfiguration
                              .enableUserPasswordResetWaitTime
                          }
                        >
                          Enable User Password Reset Wait Time
                        </Toggle>
                      </div>
                      <div>
                        <FormField
                          label="Warn User Due To Inactivity Threshold(In Days)"
                          errorText={
                            errors.portalConfiguration
                              ?.warnUserDueToInactivityThresholdInDays
                          }
                        >
                          <Input
                            type="text"
                            value={values.portalConfiguration?.warnUserDueToInactivityThresholdInDays?.toString()}
                            onChange={({ detail }) =>
                              setFieldValue(
                                'portalConfiguration.warnUserDueToInactivityThresholdInDays',
                                Number(detail.value)
                              )
                            }
                            invalid={
                              !!errors.portalConfiguration
                                ?.warnUserDueToInactivityThresholdInDays
                            }
                          />
                        </FormField>
                        <Toggle
                          onChange={({ detail }) =>
                            setFieldValue(
                              'portalConfiguration.enableWarnUserDueToInactivity',
                              detail.checked
                            )
                          }
                          checked={
                            values.portalConfiguration
                              .enableWarnUserDueToInactivity
                          }
                        >
                          Enable warning users of deactivation due to inactivity
                        </Toggle>
                      </div>
                      <div>
                        <FormField
                          label="Disable User Due To Inactivity Threshold(In Days)"
                          errorText={
                            errors.portalConfiguration
                              ?.disableUserDueToInactivityThresholdInDays
                          }
                        >
                          <Input
                            type="text"
                            value={values.portalConfiguration?.disableUserDueToInactivityThresholdInDays?.toString()}
                            onChange={({ detail }) =>
                              setFieldValue(
                                'portalConfiguration.disableUserDueToInactivityThresholdInDays',
                                Number(detail.value)
                              )
                            }
                            invalid={
                              !!errors.portalConfiguration
                                ?.disableUserDueToInactivityThresholdInDays
                            }
                          />
                        </FormField>
                        <Toggle
                          onChange={({ detail }) =>
                            setFieldValue(
                              'portalConfiguration.enableDisableUserDueToInactivity',
                              detail.checked
                            )
                          }
                          checked={
                            values.portalConfiguration
                              .enableDisableUserDueToInactivity
                          }
                        >
                          Enable disable users due to inactivity
                        </Toggle>
                      </div>
                      <div>
                        <Toggle
                          onChange={({ detail }) =>
                            setFieldValue(
                              'portalConfiguration.enableAutoProvision',
                              detail.checked
                            )
                          }
                          checked={
                            values.portalConfiguration.enableAutoProvision
                          }
                        >
                          Allow new user requests to auto-provision WorkSpace
                        </Toggle>
                      </div>
                      <div>
                        <FormField
                          label="SMS Code Expiration Time (In Seconds)"
                          errorText={
                            errors.portalConfiguration
                              ?.smsVerifyExpireTimeInSeconds
                          }
                        >
                          <Input
                            type="text"
                            value={values.portalConfiguration.smsVerifyExpireTimeInSeconds.toString()}
                            onChange={({ detail }) =>
                              setFieldValue(
                                'portalConfiguration.smsVerifyExpireTimeInSeconds',
                                Number(detail.value)
                              )
                            }
                            invalid={
                              !!errors.portalConfiguration
                                ?.smsVerifyExpireTimeInSeconds
                            }
                          />
                        </FormField>
                      </div>
                      <div>
                        <FormField
                          label="SMS Code Max Resends"
                          errorText={
                            errors.portalConfiguration
                              ?.smsVerifyMaxResends
                          }
                        >
                          <Input
                            type="text"
                            value={values.portalConfiguration.smsVerifyMaxResends.toString()}
                            onChange={({ detail }) =>
                              setFieldValue(
                                'portalConfiguration.smsVerifyMaxResends',
                                Number(detail.value)
                              )
                            }
                            invalid={
                              !!errors.portalConfiguration
                                ?.smsVerifyMaxResends
                            }
                          />
                        </FormField>
                      </div>
                      <div>
                        <FormField
                          label="SMS Code Max Failed Attempts"
                          errorText={
                            errors.portalConfiguration
                              ?.smsVerifyMaxFailures
                          }
                        >
                          <Input
                            type="text"
                            value={values.portalConfiguration.smsVerifyMaxFailures.toString()}
                            onChange={({ detail }) =>
                              setFieldValue(
                                'portalConfiguration.smsVerifyMaxFailures',
                                Number(detail.value)
                              )
                            }
                            invalid={
                              !!errors.portalConfiguration
                                ?.smsVerifyMaxFailures
                            }
                          />
                        </FormField>
                      </div>
                    </SpaceBetween>
                  </Container>
                </SpaceBetween>
              </ExpandableSection>
              <ExpandableSection
                header="Secrets"
                expanded={secretsSectionExpanded}
                onChange={({ detail }) => {
                  setSecretsSectionExpanded(detail.expanded);
                }}
              >
                <SpaceBetween direction="vertical" size="m">
                  <Container
                    header={
                      <Header variant="h2">DUO Integration Secrets</Header>
                    }
                  >
                    <SpaceBetween direction="vertical" size="xs">
                      <FormField
                        label="DUO API Endpoint"
                        errorText={errors.duoIntegration?.apiEndpoint}
                      >
                        <Input
                          type={viewDuoSecrets ? 'text' : 'password'}
                          value={values.duoIntegration.apiEndpoint}
                          onChange={({ detail }) =>
                            setFieldValue(
                              'duoIntegration.apiEndpoint',
                              detail.value
                            )
                          }
                          invalid={!!errors.duoIntegration?.apiEndpoint}
                        />
                      </FormField>
                      <FormField
                        label="DUO Integration Key"
                        errorText={errors.duoIntegration?.integrationKey}
                      >
                        <Input
                          type={viewDuoSecrets ? 'text' : 'password'}
                          value={values.duoIntegration.integrationKey}
                          onChange={({ detail }) =>
                            setFieldValue(
                              'duoIntegration.integrationKey',
                              detail.value
                            )
                          }
                          invalid={!!errors.duoIntegration?.integrationKey}
                        />
                      </FormField>
                      <FormField
                        label="Duo Secret Key"
                        errorText={errors.duoIntegration?.secretKey}
                      >
                        <Input
                          type={viewDuoSecrets ? 'text' : 'password'}
                          value={values.duoIntegration.secretKey}
                          onChange={({ detail }) =>
                            setFieldValue(
                              'duoIntegration.secretKey',
                              detail.value
                            )
                          }
                          invalid={!!errors.duoIntegration?.secretKey}
                        />
                      </FormField>
                      <FormField
                        label="DUO Directory Key"
                        errorText={errors.duoIntegration?.directoryKey}
                      >
                        <Input
                          type={viewDuoSecrets ? 'text' : 'password'}
                          value={values.duoIntegration.directoryKey}
                          onChange={({ detail }) =>
                            setFieldValue(
                              'duoIntegration.directoryKey',
                              detail.value
                            )
                          }
                          invalid={!!errors.duoIntegration?.directoryKey}
                        />
                      </FormField>

                      <Toggle
                        onChange={({ detail }) =>
                          setFieldValue(
                            'portalConfiguration.duoEnabled',
                            detail.checked
                          )
                        }
                        checked={values.portalConfiguration.duoEnabled}
                      >
                        DUO Enabled
                      </Toggle>

                      <Button
                        onClick={() =>
                          setViewDuoSecrets((prevState) => !prevState)
                        }
                      >
                        {viewDuoSecrets ? 'Hide' : 'View'}
                      </Button>
                    </SpaceBetween>
                  </Container>
                  <Container
                    header={
                      <Header variant="h2">WorkMail Integration Settings</Header>
                    }
                  >
                    <SpaceBetween direction="vertical" size="xs">
                      <FormField
                        label="WorkMail Domain"
                        errorText={errors.portalConfiguration?.workMailDomain}
                      >
                        <Input
                          type={'text'}
                          value={values.portalConfiguration.workMailDomain}
                          onChange={({ detail }) =>
                            setFieldValue(
                              'portalConfiguration.workMailDomain',
                              detail.value
                            )
                          }
                          invalid={!!errors.portalConfiguration?.workMailDomain}
                        />
                      </FormField>
                      <FormField
                        label="WorkMail Organization ID"
                        errorText={errors.portalConfiguration?.workMailOrgId}
                      >
                        <Input
                          type={'text'}
                          value={values.portalConfiguration.workMailOrgId}
                          onChange={({ detail }) =>
                            setFieldValue(
                              'portalConfiguration.workMailOrgId',
                              detail.value
                            )
                          }
                          invalid={!!errors.portalConfiguration?.workMailOrgId}
                        />
                      </FormField>

                      <Toggle
                        onChange={({ detail }) =>
                          setFieldValue(
                            'portalConfiguration.workMailEnabled',
                            detail.checked
                          )
                        }
                        checked={values.portalConfiguration.workMailEnabled}
                      >
                        WorkMail Enabled
                      </Toggle>
                    </SpaceBetween>
                  </Container>
                  <Container
                    header={
                      <Header variant="h2">WorkDocs Integration Settings</Header>
                    }
                  >
                    <Toggle
                      onChange={({ detail }) =>
                        setFieldValue(
                          'portalConfiguration.workDocsEnabled',
                          detail.checked
                        )
                      }
                      checked={values.portalConfiguration.workDocsEnabled}
                    >
                      WorkDocs Enabled
                    </Toggle>
                  </Container>
                </SpaceBetween>
              </ExpandableSection>
            </SpaceBetween>
          </Form>
        </SpaceBetween>
      )}
    </Formik>
  );
};
export default View;